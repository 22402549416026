import {
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
  CSSProperties,
} from "react";
import { areEqual, FixedSizeList as List } from "react-window";

import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List as CList,
  Text,
  Button,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ClearIcon } from "components/icons/ClearIcon";

import { IOrganization } from "modules/organization/application/types/IOrganization";
import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

import { OrganizationStatusBadge } from "../../OrganizationStatusBadge";

interface IProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const MAX_MANAGERS_COUNT = 50;

export const OrganizationSearch = ({ value, onChange }: IProps) => {
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    data: { contextsManagers },
  } = useContextsManagersQuery();
  const { data } = useOrganizationsQuery();

  const sortedOrganizations = useMemo(
    () =>
      reverse(
        sortBy(
          data?.organizations.filter((organization) => {
            if (search === "") {
              return true;
            }

            return (
              organization.details.displayName
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              organization.details.email
                .toLowerCase()
                .includes(search.toLowerCase())
            );
          }),
          function (data) {
            return new Date(data.createdAt);
          }
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.organizations.length, search]
  );

  const options = sortedOrganizations.map((organization) => {
    return {
      ...organization,
      managersCountExcited:
        contextsManagers?.filter(({ organizations }) =>
          organizations.find(({ id }) => id === organization.id)
        ).length >= MAX_MANAGERS_COUNT,
    };
  });

  const setManagerName = () => {
    setSearch(
      data?.organizations.find((organization) => organization.id === value)
        ?.details.displayName || ""
    );
  };

  useEffect(() => {
    if (value) {
      setManagerName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!containerRef.current?.contains(event.relatedTarget as Node)) {
      setFocused(false);
    }
  };

  return (
    <>
      <VStack
        align="stretch"
        spacing={3}
        ref={containerRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={-1}
      >
        <InputGroup>
          <Input
            value={search}
            placeholder={t("Szukaj")}
            onChange={(e) => setSearch(e.target.value)}
          />
          {value && (
            <InputRightElement width="4.5rem">
              <IconButton
                aria-label="clear"
                h="1.75rem"
                size="sm"
                variant="ghost"
                colorScheme="purple"
                icon={<ClearIcon />}
                onClick={() => {
                  setSearch("");
                  onChange(null);
                }}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <Box position="relative" display={focused ? "block" : "none"}>
          <CList
            position="absolute"
            zIndex={1}
            maxH="310px"
            left={0}
            right={2}
            overflowY="auto"
            bgColor="white"
            boxShadow="md"
          >
            <List<DataProps>
              height={options.length > 6 ? 310 : options.length * 54}
              itemCount={options.length}
              itemSize={50}
              itemData={{
                options: options,
                onChange: (value) => {
                  onChange(value);
                  document.getElementById("focus-trap")?.focus();
                  setFocused(false);
                },
              }}
              width="100%"
            >
              {Row}
            </List>
          </CList>
        </Box>
      </VStack>
      <div id="focus-trap" tabIndex={-1} />
    </>
  );
};

interface DataProps {
  options: (IOrganization & {
    managersCountExcited: boolean;
  })[];
  onChange: (value: string | null) => void;
}

interface Props {
  index: number;
  data: DataProps;
  style: CSSProperties;
}

const Row = memo<Props>(({ data, index, style }) => {
  const option = data.options[index];
  const onChange = data.onChange;

  return (
    <Button
      variant="unstyled"
      textAlign="left"
      fontWeight={400}
      _hover={{ bgColor: "gray.100" }}
      p={2}
      cursor={option.managersCountExcited ? "not-allowed" : "pointer"}
      opacity={option.managersCountExcited ? 0.5 : 1}
      onClick={() => {
        if (!option.managersCountExcited) {
          onChange(option.id);
        }
      }}
      title={
        option.managersCountExcited
          ? "Maksymalna liczba administratorów nie może przekraczać 50"
          : undefined
      }
      pointerEvents="all"
      width="100%"
      style={style}
    >
      <HStack>
        <Text fontWeight="500">{option.details.displayName}</Text>
        <OrganizationStatusBadge status={option.status} />
      </HStack>
      <Text fontSize="sm" color="gray.600">
        {option.details.email}
      </Text>
    </Button>
  );
}, areEqual);
