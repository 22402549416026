import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

interface Response {
  active: number;
  blocked: number;
  total: number;
}

const query = () => {
  return {
    queryKey: ["contextsManagers", "dashboard"],
    queryFn: () =>
      client.get<{ contextsManagers: Response }>(`contextsManagers/dashboard`),
  };
};

export const getContextsManagersDashboardQuery =
  (queryClient: QueryClient) => () => {
    return queryClient.ensureQueryData(query());
  };

export const useContextsManagersDashboardQuery = () => {
  return useQuery(query());
};

export const getContextsManagersDashboardMock = (
  contextsManagers?: Response
) => {
  return http.get(withHost("contextsManagers/dashboard"), () => {
    return HttpResponse.json<{ contextsManagers: Response }>({
      contextsManagers: {
        active: 1864,
        blocked: 0,
        total: 1864,
        ...contextsManagers,
      },
    });
  });
};
