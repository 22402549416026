import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { SearchInput } from "components/Filters/SearchInput";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IContextManagerResponse } from "modules/organization/application/types/IContextManagerResponse";
import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";

import { AssignManagerButton } from "./AssignManagerButton";
import { ImpersonateButton } from "./ImpersonateButton";
import { ManagedOrganizations } from "./ManagedOrganizations";
import { ManagersWithOrganizationsFiltersDrawer } from "./ManagersWithOrganizationsFiltersDrawer";

const columnHelper = createColumnHelper<IContextManagerResponse>();

const columns = [
  columnHelper.accessor("name", {
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="context-name">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("email", {
    header: () => t("E-mail"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("active", {
    id: "active",
    filterFn: (row, id, filterValue: string[]) => {
      if (!filterValue.length) return true;

      return filterValue.includes((row.getValue(id) as boolean).toString());
    },
    header: () => t("Aktywny"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <ActiveBadge isActive={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Utworzony"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor(
    (row) => row.organizations.map(({ name }) => name).join(","),
    {
      id: "organizations",
      enableSorting: false,
      header: () => t("Powiązane podmioty"),
      cell: (info) => {
        const { organizations } = info.row.original;

        return (
          <Td whiteSpace="normal">
            <ManagedOrganizations
              organizations={organizations}
              contextId={info.row.original.id}
              contextName={info.row.original.name}
            />
          </Td>
        );
      },
    }
  ),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <AssignManagerButton contextId={info.getValue()} />
          <ImpersonateButton email={info.row.original.email} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  managers: IContextManagerResponse[];
  isLoading: boolean;
}

const TableConnected = ({ managers, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (managers.length === 0) {
    return <TableEmptyState title={t("Lista administratorów jest pusta.")} />;
  }

  const sortedRecords = reverse(
    sortBy(managers, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<IContextManagerResponse> data={sortedRecords} columns={columns} />
  );
};

export const ManagersWithOrganizationsTable = () => {
  const { data, isLoading } = useContextsManagersQuery();

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Administratorzy podmiotów")}</Heading>
        <Spacer />
        <SearchInput placeholder={t("Nazwa, email lub podmiot")} />
        <ManagersWithOrganizationsFiltersDrawer />
      </CardHeader>
      <CardBody>
        <TableConnected
          managers={data?.contextsManagers}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
