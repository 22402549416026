import { CSSProperties, memo, useEffect, useRef, useState } from "react";
import { areEqual, FixedSizeList as List } from "react-window";

import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List as CList,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { ClearIcon } from "components/icons/ClearIcon";

import { IContextManagerResponse } from "modules/organization/application/types/IContextManagerResponse";
import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";

interface IProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

export const ContextManagerSearch = ({ value, onChange }: IProps) => {
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { data } = useContextsManagersQuery();

  const managers = reverse(
    sortBy(
      data?.contextsManagers.filter((manager) => {
        if (search === "") {
          return true;
        }

        return (
          manager.name.toLowerCase().includes(search.toLowerCase()) ||
          manager.email.toLowerCase().includes(search.toLowerCase())
        );
      }),
      function (data) {
        return new Date(data.createdAt);
      }
    )
  );

  const setManagerName = () => {
    setSearch(
      data?.contextsManagers.find((manager) => manager.id === value)?.name || ""
    );
  };

  useEffect(() => {
    if (value) {
      setManagerName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!containerRef.current?.contains(event.relatedTarget as Node)) {
      setFocused(false);
    }
  };

  return (
    <>
      <VStack
        align="stretch"
        spacing={3}
        ref={containerRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={-1}
      >
        <InputGroup>
          <Input
            value={search}
            placeholder={t("Szukaj")}
            onChange={(e) => setSearch(e.target.value)}
          />
          {value && (
            <InputRightElement width="4.5rem">
              <IconButton
                aria-label="clear"
                h="1.75rem"
                size="sm"
                variant="ghost"
                colorScheme="purple"
                icon={<ClearIcon />}
                onClick={() => {
                  setSearch("");
                  onChange(null);
                }}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <Box position="relative" display={focused ? "block" : "none"}>
          <CList
            position="absolute"
            zIndex={1}
            maxH="310px"
            left={0}
            right={2}
            overflowY="auto"
            bgColor="white"
            boxShadow="md"
          >
            <List<DataProps>
              height={managers.length > 6 ? 310 : managers.length * 54}
              itemCount={managers.length}
              itemSize={50}
              itemData={{
                options: managers,
                onChange: (value) => {
                  onChange(value);
                  document.getElementById("focus-trap")?.focus();
                  setFocused(false);
                },
              }}
              width="100%"
            >
              {Row}
            </List>
          </CList>
        </Box>
      </VStack>
      <div id="focus-trap" tabIndex={-1} />
    </>
  );
};

interface DataProps {
  options: IContextManagerResponse[];
  onChange: (value: string | null) => void;
}

interface Props {
  index: number;
  data: DataProps;
  style: CSSProperties;
}

const Row = memo<Props>(({ data, index, style }) => {
  const manager = data.options[index];
  const onChange = data.onChange;

  return (
    <Button
      variant="unstyled"
      textAlign="left"
      fontWeight={400}
      _hover={{ bgColor: "gray.100" }}
      p={2}
      onClick={() => {
        onChange(manager.id);
      }}
      pointerEvents="all"
      width="100%"
      style={style}
    >
      <HStack>
        <Text fontWeight="500">{manager.name}</Text>
        <ActiveBadge isActive={manager.active} />
      </HStack>
      <Text fontSize="sm" color="gray.600">
        {manager.email}
      </Text>
    </Button>
  );
}, areEqual);
